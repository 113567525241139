<template>
  <div class="bg">
    <div>
      <div class="center-vertical">
        <img
          src="/logo/lgo.png"
          alt="logo-lotto-vip"
        >
      <!-- button router-link to="/Lottery" -->
      </div>

    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      invcode: this.$route.params.invcode,
    }
  },
  mounted() {
    if (this.$route.params.invcode) {
      this.invcode = this.$route.params.invcode
    }
  },
  methods: {
    LinkToLine() {
      window.location.href = 'https://line.me/R/ti/p/@670wdtom'
    },
  },
}
</script>

<style scoped>
.bg {
  background-color: #fff;
  height: 100vh;
}
.bg {
  background-image: url(/logo/bg3.svg),linear-gradient(154.06deg, #b70009 0%, #500009 100%) ;
  background-size: 50px;
  background-repeat: repeat;
  /* background: ; */
}
.center-vertical {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* img center vertical css*/
img {
    width: auto;
    height: 150px;
}

</style>
